<template>
    <div>
        <a-sidebar></a-sidebar>
        <div class="main-content" id="panel">
            <a-mobile-sidebar></a-mobile-sidebar>
            <a-header title="Cadres" class="bg-success text-dark">
                <div class="col-md-12">
                    <stats-card card-title="Cadres" card-sub-title="Employee Cadres">
                    </stats-card>
                </div>
            </a-header>
            <a-content>
                <div class="col-md-8">
                    <table-card card-title="Created Employee Cadres">
                        <table class="table align-items-center table-flush" v-if="records">
                            <thead class="thead-light">
                            <tr>
                                <th scope="col">Branch Name</th>
                                <th scope="col">Cadre</th>
                                <th scope="col">Charge / Day</th>
                                <th scope="col"><i class="ni ni-ui-04"></i></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="record in records" :key="record.cadre_id">
                                <td>{{ record.branch.name }}</td>
                                <td>{{ record.name }}</td>
                                <td>{{ record.charge_per_day }}</td>
                                <td><button @click="openUpdateForm(record)" class="btn btn-secondary btn-sm"><i class="fas fa-edit"></i></button></td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else class="alert alert-secondary">
                            No Records Yet!
                        </div>
                    </table-card>
                </div>
                <div class="col-md-4">
                    <form-card card-title="Edit Record Form" card-sub-title="Update Cadre" v-if="showUpdateForm">
                        <form v-on:submit.prevent="updateForm">
                            <div class="form-group mb-3">
                                <div>
                                    <label>Name</label>
                                    <input class="form-control" type="text" v-model="form.name">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Charge Per day</label>
                                    <input class="form-control" type="number" step="0.01" v-model="form.charge_per_day" onwheel="this.blur()">
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-warning my-4"><i class="ni ni-check-bold"></i> Update</button> &nbsp; 
                                <button @click="closeUpdateForm" class="btn btn-default my-4"><i class="ni ni-fat-remove"></i> Close</button>
                            </div>
                        </form>
                    </form-card>
                    <form-card card-title="New Form" card-sub-title="Create New Cadre" v-else>
                        <form v-on:submit.prevent="createForm">
                            <div class="form-group mb-3">
                                <div>
                                    <label>Name</label>
                                    <input class="form-control" type="text" v-model="form.name">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Charge Per day</label>
                                    <input class="form-control" type="number" step="0.01" v-model="form.charge_per_day" onwheel="this.blur()">
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary my-4"><i class="ni ni-check-bold"></i> Create</button>
                            </div>
                        </form>
                    </form-card>
                </div>
            </a-content>
            <a-footer></a-footer>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import AdminContentVue from '../../components/Admin/AdminContent.vue'
import AdminFooterVue from '../../components/Admin/AdminFooter.vue'
import AdminHeaderVue from '../../components/Admin/AdminHeader.vue'
import NoButtonWhiteCardVue from '../../components/Cards/NoButtonWhiteCard.vue'
import StatsCardVue from '../../components/Cards/StatsCard.vue'
import TableOneButtonCardVue from '../../components/Cards/TableOneButtonCard.vue'
import AdminMobileNavVue from '../../components/Nav/AdminMobileNav.vue'
import AdminSideBarVue from '../../components/Nav/AdminSideBar.vue'
export default {
    name: 'AdminCadre',
    components: {
        'a-header': AdminHeaderVue,
        'a-mobile-sidebar': AdminMobileNavVue,
        'a-sidebar': AdminSideBarVue,
        'a-content': AdminContentVue,
        'a-footer': AdminFooterVue,
        'stats-card': StatsCardVue,
        'table-card': TableOneButtonCardVue,
        'form-card': NoButtonWhiteCardVue, 
    },
    computed : {
        ...mapState({
            authToken : state => state.authToken,
            branch : state => state.branch,
        })
    },
    mounted(){
        if (this.authToken) {
            this.getRecords()
        }
        else{
            this.$router.push('/login')
        }
    },
    data() {
        return{
            records: null,
            showUpdateForm: false,
            form:{
                name: null,
                charge_per_day: null
            }
        }
    },
    methods:{
        createForm(){
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/admin/cadres?branch_id='+this.branch.branch_id, this.form,
            {
                headers: { 'Authorization': 'Bearer '+this.authToken }
            })
            .then((response) => {
                //Perform Success Action
                this.$moshaToast(response.data.message,{
                    type: 'success'
                });
                this.getRecords();
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
            })
            .finally(() => {
                //Perform other dependent actions
                loader.hide()
            });
        },

        updateForm(){
            let loader = this.$loading.show()
            axios.put(process.env.VUE_APP_BASEURL+'/admin/cadres/'+this.recordId+'?branch_id='+this.branch.branch_id, this.form,
            { 
                headers: { 'Authorization': 'Bearer '+this.authToken }
            })
            .then((response) => {
                //Perform Success Action
                this.$moshaToast(response.data.message,{
                    type: 'success'
                });
                this.getRecords();
            })
            .catch((error) => {
                // error.response.status Check status code
                if (error.response.status === 422) {
                    this.$moshaToast(JSON.stringify(error.response.data.errors),{
                        type: 'danger'
            
                    });                        
                }else{
                    this.$moshaToast(error.response.data.message,{
                        type: 'danger'
                    });
                }
            })
            .finally(() => {
            //Perform other dependent actions
            loader.hide()
            });
        },

        getRecords(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/cadres',
            {
                headers: { 'Authorization': 'Bearer '+this.authToken }
            })
            .then(response => {
                this.records = response.data.data
            })
            .catch(error => {
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
            })
        },

        openUpdateForm(record){
            this.showUpdateForm = true,
            this.recordId = record.cadre_id,
            this.form.name = record.name,
            this.form.charge_per_day = record.charge_per_day
        },
        closeUpdateForm(){
            this.showUpdateForm = false,
            this.form.name = null,
            this.form.charge_per_day = null
        }

    },
}
</script>